.page-contato {
    border-radius: 0.25em;
    background-color: #ffffff;
}

label {
    padding-left: 15px;
    padding-top: 15px;
    font-weight: bold;
}

.form-dut {
    background-color: #FFFFFF;
}

.form-row {
    margin: 15px;
}

.formContatoOptions {
    margin-bottom: 15px;
}